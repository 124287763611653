import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {Col, Row, Table} from "react-bootstrap";
import '../assets/style.css';

const ElephantList = observer(() => {
    const {elephant,croppers,user} = useContext(Context)
    if(elephant.elephants.length){
        return (
            <Row className="flex">
                <Table className={"mt-3"} size="sm" style={{maxWidth: '500px'}}>
                    <tr className="mt-3 font-weight-bold">
                        <td>x | y</td>
                        <td>Animals</td>
                        <td>Dist</td>
                    </tr>
                    {elephant.elephants.map(item =>
                        <tr key={item.id}>
                            <td>
                                <a target="_blank" rel="noreferrer" href={`https://${elephant.selectedServer.server}/position_details.php?x=${item.x}&y=${item.y}`}>
                                    {item.x} | {item.y}
                                </a>
                            </td>
                            <td>
                                {item.t31 ? <span className={"mr-2"}><i className={"unit u31"}></i>&nbsp;{item.t31}</span> : null}
                                {item.t32 ? <span className={"mr-2"}><i className={"unit u32"}></i>&nbsp;{item.t32}</span> : null}
                                {item.t33 ? <span className={"mr-2"}><i className={"unit u33"}></i>&nbsp;{item.t33}</span> : null}
                                {item.t34 ? <span className={"mr-2"}><i className={"unit u34"}></i>&nbsp;{item.t34}</span> : null}
                                {item.t35 ? <span className={"mr-2"}><i className={"unit u35"}></i>&nbsp;{item.t35}</span> : null}
                                {item.t36 ? <span className={"mr-2"}><i className={"unit u36"}></i>&nbsp;{item.t36}</span> : null}
                                {item.t37 ? <span className={"mr-2"}><i className={"unit u37"}></i>&nbsp;{item.t37}</span> : null}
                                {item.t38 ? <span className={"mr-2"}><i className={"unit u38"}></i>&nbsp;{item.t38}</span> : null}
                                {item.t39 ? <span className={"mr-2"}><i className={"unit u39"}></i>&nbsp;{item.t39}</span> : null}
                                {item.t40 ? <span className={"mr-2"}><i className={"unit u40"}></i>&nbsp;{item.t40}</span> : null}
                            </td>
                            <td>{item.distance.toFixed(1)}</td>
                        </tr>
                    )}
                </Table>
            </Row>
        );
    }
    else{
        return (
            <Row className="d-flex mt-3 mb-7">
                No animals found
            </Row>
        );
    }
});

export default ElephantList;